<template>
  <div>
    <h2>Обратная связь - горячая линия</h2>
    <table>
      <tr>
        <td>Информация</td>
        <td class="drop-icon"></td>
      </tr>
      <tr>
        <td v-html="getFeedbackHotline.feedback"></td>
        <td class="drop-icon">
            <router-link to="/admin/feedback_hotline/create">Редактировать</router-link>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'FeedbackHotline',
  created () {
    this.$store.dispatch('setFeedbackHotline')
  },
  computed: {
    getFeedbackHotline () {
      return this.$store.getters.getFeedbackHotline
    }
  }
}
</script>

<style scoped lang="scss">
h2{
    font-weight: 600;
    font-size: 1.37rem;
    color: #1F3245;
}
  table{
    margin-top: 2rem;
    border-spacing: 0;
    border-collapse: collapse;

    tr{
      padding: 0;
      border-bottom: 1px solid #D5D6D9;

      td {
        max-width: 23%;
        font-size: .812rem;
        line-height: 154%;
        color: #1F3245;
        padding: 1.375rem 1.375rem 1.375rem 0;

        a {
          color: #246CB7;
        }

        img {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
        }
      }

      &:first-of-type td{
        padding: 1rem 0;
        color: #5E686C;
      }
    }
  }
</style>
